<template>
  <b-row>
    <b-col cols="12">
      <BasicProductInfoSection :form="form" />
    </b-col>

    <b-col cols="12">
      <BasicProductDimension :form="form">
        <template #custom-fields>
          <b-row>
            <b-col class="6" lg="6" md="6">
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Satuan Berat :"
                label-size="sm"
              >
                <!-- select kondisi produk (baru/bekas) -->
                <b-form-radio-group
                  v-model="form.required.weight_unit"
                  class="mt-1 ml-2"
                  size="sm"
                  stacked
                  :aria-describedby="ariaDescribedby"
                  :options="[
                    { text: 'KG (Kilogram)', value: 'KG' },
                    { text: 'GR (Gram)', value: 'GR' },
                  ]"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </BasicProductDimension>
    </b-col>

    <b-col cols="12">
      <b-card title="Informasi Tambahan">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Kondisi Produk :"
              label-size="sm"
            >
              <!-- select kondisi produk (baru/bekas) -->
              <b-form-radio-group
                v-model="form.required.condition"
                size="sm"
                stacked
                class="ml-2 mt-1"
                :aria-describedby="ariaDescribedby"
                :options="[
                  { text: 'Baru', value: 'NEW' },
                  { text: 'Bekas', value: 'USED' },
                ]"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Status Produk :"
              label-size="sm"
            >
              <!-- select kondisi produk (baru/bekas) -->
              <b-form-radio-group
                v-model="form.required.status"
                size="sm"
                stacked
                class="ml-2 mt-1"
                :aria-describedby="ariaDescribedby"
                :options="[
                  { text: 'Tidak Terbatas', value: 'UNLIMITED' },
                  { text: 'Terbatas', value: 'LIMITED' },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- produk harus asuransi -->
          <b-col cols="6">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Asuransi :"
              label-size="sm"
            >
              <b-form-radio-group
                v-model="form.required.is_must_insurance"
                size="sm"
                stacked
                class="ml-2 mt-1"
                :aria-describedby="ariaDescribedby"
                :options="[
                  { text: 'Ya', value: 'YES' },
                  { text: 'Tidak', value: 'NO' },
                ]"
              />
            </b-form-group>
          </b-col>

          <!-- currency (IDR/USD) -->
          <b-col cols="6">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Mata Uang :"
              label-size="sm"
            >
              <b-form-radio-group
                v-model="form.required.price_currency"
                size="sm"
                stacked
                class="ml-2 mt-1"
                :aria-describedby="ariaDescribedby"
                :options="[
                  { text: 'IDR', value: 'IDR' },
                  { text: 'USD', value: 'USD' },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- preorder -->
        <b-row>
          <b-col cols="6">
            <b-form-group label="Produk Preorder :" label-size="sm">
              <b-form-checkbox
                v-model="form.required.preorder.is_active"
                size="md"
                switch
              >
                {{
                  form.required.preorder.is_active
                    ? 'Preorder Diaktifkan'
                    : 'Aktifkan Preorder'
                }}
              </b-form-checkbox>
              <small>
                <span class="text-danger">*</span>
                Aktifkan Preorder untuk produk yang belum tersedia
              </small>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-start">
              <b-form-group
                :disabled="!form.required.preorder.is_active"
                label="Durasi Preorder :"
                label-size="sm"
                class="mr-1"
              >
                <cleave
                  v-model="form.required.preorder.duration"
                  :options="{
                    numeral: true,
                    numeralDecimalMark: ',',
                    numeralPositiveOnly: true,
                    delimiter: '.',
                  }"
                  class="form-control form-control-sm"
                  placeholder="Durasi Preorder"
                />
              </b-form-group>
              <b-form-group
                :disabled="!form.required.preorder.is_active"
                label="Satuan Waktu :"
                label-size="sm"
              >
                <b-form-select
                  v-model="form.required.preorder.time_unit"
                  size="sm"
                  :options="[
                    { text: 'Hari', value: 'DAY' },
                    { text: 'Minggu', value: 'WEEK' },
                    { text: 'Bulan', value: 'MONTH' },
                  ]"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <!-- pilih toko -->
          <b-col cols="6">
            <b-form-group label="Pilih Toko :" label-size="sm">
              <b-form-select
                v-model="form.required.shop_id"
                size="sm"
                :options="shops"
                placeholder="Pilih Toko"
              />
            </b-form-group>
          </b-col>

          <!-- minimal pembelian -->
          <b-col cols="6">
            <b-form-group label="Minimal Pembelian :" label-size="sm">
              <cleave
                v-model="form.required.minimal_purchase"
                :options="{
                  numeral: true,
                  numeralDecimalMark: ',',
                  numeralPositiveOnly: true,
                  delimiter: '.',
                }"
                class="form-control form-control-sm"
                placeholder="Minimal Pembelian"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- kategori produk dari text bounce menjadi select button -->
          <b-col cols="12">
            <b-form-group label="Kategori Produk :" label-size="sm">
              <b-form-input
                v-model="categoryInput"
                :disabled="form.required.shop_id === ''"
                size="sm"
                autocomplete="off"
                :placeholder="
                  form.required.shop_id === ''
                    ? 'Pilih Toko Terlebih Dahulu'
                    : 'Cari Kategori (Minimal 2 kata)'
                "
                @input="debounceGetCategory"
              />
            </b-form-group>

            <!-- radio button from categories -->
            <b-form-radio-group
              v-model="form.required.category_id"
              class="mt-1 ml-2"
              size="sm"
              stacked
              :options="categories"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col v-if="annotations.length > 0" cols="12">
      <b-card title="Anotasi Produk">
        <b-row v-for="(annotation, index) in annotations" :key="index">
          <b-col cols="12">
            <b-form-group :label="annotation.variant" label-size="sm">
              <b-form-input
                v-if="annotation.values === null"
                v-model="form.required.annotations[annotation.sort_order]"
                size="sm"
              />
              <b-form-select
                v-if="
                  annotation.values !== null && annotation.values.length > 0
                "
                v-model="form.required.annotations[annotation.sort_order]"
                size="sm"
                :options="
                  annotation.values.map(value => ({
                    text: value.name,
                    value: value.id,
                  }))
                "
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col cols="12">
      <b-card title="Foto dan Video Produk">
        <!-- youtbe url -->
        <b-row>
          <b-col>
            <!-- CREATE DYNAMIC FORM -->
            <b-form-group label="Video Produk (Youtube) :" label-size="sm">
              <b-form-input
                v-model="form.required.videos"
                size="sm"
                placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxx atau https://youtu.be/xxxxxxxxxxx"
              />
              <small
                v-if="form.required.videos"
                :class="
                  form.required.videos.match(youtubeId)
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                <span class="text-danger">*</span>
                {{
                  form.required.videos.match(youtubeId)
                    ? 'Youtube ID Valid'
                    : 'Youtube ID Tidak Valid'
                }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label="Foto Produk :" label-size="sm">
              <b-form-file
                v-model="selectImages"
                :disabled="
                  tableImages.rows.length +
                    (form.existingImages && form.existingImages.length
                      ? form.existingImages.length
                      : 0) >=
                  5
                "
                size="sm"
                placeholder="Pilih Foto"
                accept="image/*"
                multiple
                @input="previewImages"
              />
            </b-form-group>
            <b-table-simple ref="table" responsive="sm" bordered striped>
              <b-tbody>
                <b-tr v-for="(row, index) in tableImages.rows" :key="row.key">
                  <b-td>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-img
                        :src="row.image"
                        fluid
                        thumbnail
                        alt="image"
                        style="
                          object-fit: cover;
                          object-position: center;
                          width: 74px;
                          height: 74px;
                        "
                        width="84"
                        height="84"
                      />
                      <span class="ml-1">{{ row.sizeHuman }}</span>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        @click="deleteImage(index)"
                      >
                        <Feather-icon icon="Trash2Icon" /> Hapus Foto
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-for="(row, index) in form.existingImages" :key="index">
                  <b-td>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-img
                        :src="row"
                        fluid
                        thumbnail
                        alt="image"
                        style="
                          object-fit: cover;
                          object-position: center;
                          width: 74px;
                          height: 74px;
                        "
                      />
                      <span>-</span>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        @click="deleteExistingImage(index)"
                      >
                        <Feather-icon icon="Trash2Icon" /> Hapus Foto
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <small>
              {{
                tableImages.rows.length +
                (form.existingImages && form.existingImages.length
                  ? form.existingImages.length
                  : 0)
              }}
              Foto Produk</small
            >
            <!-- submit button dan reset button -->
            <div class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="submitData"
              >
                <Feather-icon icon="SaveIcon" size="16" /> Simpan Produk
              </b-button>
              <b-button
                variant="warning"
                size="sm"
                class="mr-1"
                :disabled="$route.params.id"
                @click="resetForm"
              >
                <Feather-icon icon="XIcon" size="16" /> Reset Formulir
              </b-button>
              <!--back button-->
              <b-button
                variant="danger"
                size="sm"
                :to="{ name: 'ProductsTokopedia' }"
              >
                <Feather-icon icon="ChevronLeftIcon" size="16" /> Kembali
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col v-if="debugmode" cols="12">
      <b-card title="Debug Mode">
        <pre>{{ form }}</pre>
        <pre>{{ tableImages }}</pre>

        <b-button
          variant="secondary"
          size="sm"
          block
          @click="fillFormWithFakeData"
        >
          <Feather-icon icon="RefreshCwIcon" size="16" /> Fake Data
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormSelect,
  BTableSimple,
  BFormRadioGroup,
  BFormRadio,
  BTbody,
  BTr,
  BTd,
  BImg,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { faker } from '@faker-js/faker';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import axios from '@axios';
import Cleave from 'vue-cleave-component';
import _ from 'lodash';

/* import components */
import BasicProductInfoSection from './form-components/BasicProductInfoSection.vue';
import BasicProductDimension from './form-components/BasicProductDimension.vue';

export default defineComponent({
  name: 'FormTokopedia',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BTableSimple,
    BFormSelect,
    BTbody,
    BTr,
    BTd,
    BImg,
    BButton,
    FeatherIcon,
    BasicProductInfoSection,
    BasicProductDimension,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    Cleave,
  },
  data() {
    return {
      form: {
        sku: '',
        title: '',
        stock: 0,
        price: 0,
        description: '',
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        required: {
          condition: 'NEW',
          weight_unit: 'KG',
          status: 'UNLIMITED',
          is_must_insurance: 'NO',
          category_id: '',
          shop_id: '',
          minimal_purchase: 1,
          price_currency: 'IDR',
          annotations: [],
          videos: '',
          preorder: {
            is_active: false,
            duration: 1,
            time_unit: 'DAY',
          },
        },
      },
      categoryInput: '',
      categories: [],
      variants: [],
      annotations: [],
      shops: [],
      tableImages: {
        rows: [],
      },
      selectImages: [],
      debugmode: false,
    };
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const debugMode = urlParams.has('debugmode');

    const urlIsAdd = window.location.pathname.includes('add');
    const urlIsEdit = window.location.pathname.includes('edit');

    if (debugMode && urlIsAdd) {
      this.fillFormWithFakeData();
      this.debugmode = true;
    }

    if (debugMode && urlIsEdit) {
      this.debugmode = true;
    }

    if (this.$route.params.id) {
      await this.fetchData();
    }

    await this.getMarketplace();
  },
  computed: {
    // regex get youtube video id
    youtubeId() {
      // eslint-disable-next-line no-useless-escape
      return /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    },
  },
  // make sure images has only 5 items
  watch: {
    'tableImages.rows': {
      handler() {
        if (
          this.tableImages.rows.length +
            (this.form.existingImages && this.form.existingImages.length
              ? this.form.existingImages.length
              : 0) >
          5
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Galat',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Maksimal 5 Foto Produk',
            },
          });
          this.tableImages.rows.pop();
        }
      },
      deep: true,
    },
    // categoryInput jika kosong maka categories di reset
    categoryInput() {
      this.categories = [];
      this.annotations = [];
      this.form.required.category_id = '';
      this.form.required.annotations = [];
    },
    // minimal_purchase jika kosong maka diisi 0
    'form.required.minimal_purchase': {
      handler(val) {
        if (val === '') {
          this.form.required.minimal_purchase = '0';
        }
      },
    },
    // preorder duration jika kosong maka diisi 1
    'form.required.preorder.duration': {
      handler(val) {
        if (val === '') {
          this.form.required.preorder.duration = '1';
        }
      },
    },
    // get annotation dari category_id
    'form.required.category_id': {
      async handler() {
        this.annotations = [];
        if (this.form.required.category_id) {
          await this.getAnnotation();
        }
      },
    },
    'form.required.shop_id': {
      handler() {
        this.categoryInput = '';
        this.categories = [];
        this.annotations = [];
        this.form.required.category_id = '';
        this.form.required.annotations = [];
      },
    },
  },
  methods: {
    fillFormWithFakeData() {
      // Fill form fields with fake data
      this.form.sku = faker.string.uuid();
      this.form.title = faker.commerce.productName();
      this.form.stock = faker.number.int({ min: 1, max: 100 });
      this.form.price = faker.commerce.price({
        min: 10000,
        max: 1000000,
        dec: 0,
      });
      this.form.description = faker.lorem.paragraph();
      this.form.weight = faker.number.int({ min: 0.1, max: 10 });
      this.form.length = faker.number.int({ min: 10, max: 100 });
      this.form.width = faker.number.int({ min: 10, max: 100 });
      this.form.height = faker.number.int({ min: 10, max: 100 });
    },

    async getAnnotation() {
      this.annotations = [];
      this.form.required.annotations = [];

      // Fetch annotation data from server
      const response = await axios.get('/tokopedia/product/annotation', {
        params: {
          categoryId: this.form.required.category_id,
          shopId: this.form.required.shop_id,
        },
      });

      if (
        response.data &&
        response.data.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        this.annotations = response.data.data;

        // create annotations array
        // this.form.required.annotations.fill('', 0, this.annotations.length);
        // console.log('annotations', this.form.required.annotations);

        const fillArray = Array(this.annotations.length).fill('');
        this.$set(this.form.required, 'annotations', fillArray);

        console.log('annotations', this.form.required.annotations);
      }
    },

    async getMarketplace() {
      this.shops = [];

      // Fetch marketplace data from server
      const response = await axios.get('/marketplace');

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        // filter data marketplaceName hanya tokopedia
        const tokopedia = response.data.data.filter(
          marketplace => marketplace.marketplaceName === 'tokopedia',
        );

        // sesuaikan dengan format b-form-select
        this.shops = tokopedia.map(shop => ({
          text: shop.shopName,
          value: shop.id,
        }));

        this.shops.push({
          text: 'Pilih Toko',
          value: '',
        });
      }
    },

    debounceGetCategory: _.debounce(async function getCategory() {
      // check category input word length
      if (this.categoryInput.split(' ').length !== 2) {
        return;
      }

      this.categories = [];

      // Fetch category data from server
      const response = await axios.get('/tokopedia/product/categories', {
        params: {
          shopId: this.form.required.shop_id,
          search: this.categoryInput,
        },
      });

      if (
        response.data &&
        response.data.data &&
        response.data.data.categories &&
        response.data.data.categories.length > 0
      ) {
        // sesuaikan dengan format b-form-radio-group
        this.categories = response.data.data.categories.map(category => ({
          text: category.name,
          value: category.id,
        }));
      }
    }, 800),

    async fetchData() {
      // Fetch data from server
      const response = await axios.get(`/product/${this.$route.params.id}`);

      // assign response data to form
      if (response && response.data && response.data.status === true) {
        this.form.title = response.data.data.title;
        this.form.sku = response.data.data.sku;
        this.form.stock = response.data.data.stock;
        this.form.price = response.data.data.price;
        this.form.description = response.data.data.description;
        this.form.weight = response.data.data.weight;
        this.form.length = response.data.data.length;
        this.form.width = response.data.data.width;
        this.form.height = response.data.data.height;

        // assign existing images to form
        this.form.existingImages = response.data.data.existingImages;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Terjadi Kesalahan Saat Mengambil Data Produk',
          },
        });

        // delay 1s and redirect to product master
        setTimeout(() => {
          this.$router.push({ name: 'productsMaster' });
        }, 1000);
      }
    },

    previewImages() {
      // check if existing images is more than 5
      if (
        this.tableImages.rows.length +
          (this.form.existingImages && this.form.existingImages.length
            ? this.form.existingImages.length
            : 0) >=
        5
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Maksimal 5 Foto Produk',
          },
        });

        this.selectImages = [];

        return;
      }

      const files = this.selectImages; // Get the files from the input

      const sizeHumanReadable = size => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / 1024 ** i).toFixed(2) * 1} ${
          ['B', 'kB', 'MB', 'GB', 'TB'][i]
        }`;
      };
      // loop through the files
      for (let i = 0; i < files.length; i += 1) {
        // if image size is more than 2MB
        if (Number(files[i].size) <= 2000000) {
          const reader = new FileReader(); // Create a new instance of FileReader for each file
          const randomKey = Math.random().toString(36).substring(7);

          reader.onload = e => {
            this.tableImages.rows.push({
              image: e.target.result,
              file: files[i],
              sizeHuman: sizeHumanReadable(files[i].size),
              name: files[i].name,
              key: `${files[i].name}-${files[i].size}-${randomKey}`,
            });

            this.$nextTick(() => {
              this.$forceUpdate();
            });
          };

          reader.readAsDataURL(files[i]);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Galat',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Foto ${files[i].name} melebihi batas ukuran 2MB`,
            },
          });
        }
      }

      // clear the input
      this.selectImages = [];
    },

    deleteImage(index) {
      this.tableImages.rows.splice(index, 1);
    },

    deleteExistingImage(index) {
      this.form.existingImages.splice(index, 1);

      // refresh the layout
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    resetForm() {
      this.form = {
        sku: '',
        title: '',
        stock: 0,
        price: 0,
        description: '',
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
      };
      this.tableImages.rows = [];
    },

    async submitData() {
      // check all form fields are filled
      const formFields = Object.values(this.form);

      // make sure image is uploaded
      if (this.tableImages.rows.length === 0 && !this.form.existingImages) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Foto Produk Harus Diunggah',
          },
        });

        return;
      }

      if (formFields.includes('') || formFields.includes(0)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Semua kolom formulir harus diisi',
          },
        });

        return;
      }

      // create form data
      const formData = new FormData();

      // append form data dynamically
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key]);
      });

      // append images
      if (this.tableImages && this.tableImages.rows.length > 0) {
        console.log('tableImages', this.tableImages);
        this.tableImages.rows.forEach(image => {
          formData.append('images', image.file);
        });
      }

      // send form data to server
      const response = await axios[this.$route.params.id ? 'put' : 'post'](
        `/product/${this.$route.params.id || ''}`,
        formData,
      );

      if (response && response.data && response.data.status === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            variant: 'success',
            text: `${
              this.$route.params.id ? 'Memperbarui' : 'Menambahkan'
            } Data Produk Berhasil`,
          },
        });

        return;
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Galat',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'Terjadi Kesalahan Saat Menyimpan Data Produk',
        },
      });
    },
  },
});
</script>

<style lang="scss">
// create square image and fill the rest with white space
.sq-image {
  object-fit: cover;
  object-position: center;
}
</style>
