<template>
  <b-card title="Dimensi dan Berat">
    <!-- berat -->
    <b-form-group label="Berat (KG/GR) :" label-size="sm">
      <cleave
        v-model="form.weight"
        :options="{
          numeral: true,
          numeralDecimalMark: ',',
          numeralPositiveOnly: true,
          delimiter: '.',
        }"
        class="form-control form-control-sm"
        placeholder="Berat (KG)"
      />
    </b-form-group>
    <!-- panjang, lebar, tinggi dibuat col 4 -->
    <b-row>
      <b-col cols="4">
        <!-- panjang -->
        <b-form-group label="Panjang (CM) :" label-size="sm">
          <cleave
            v-model="form.length"
            :options="{
              numeral: true,
              numeralDecimalMark: ',',
              numeralPositiveOnly: true,
              delimiter: '.',
            }"
            class="form-control form-control-sm"
            placeholder="Panjang (CM)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <!-- lebar -->
        <b-form-group label="Lebar (CM) :" label-size="sm">
          <cleave
            v-model="form.width"
            :options="{
              numeral: true,
              numeralDecimalMark: ',',
              numeralPositiveOnly: true,
              delimiter: '.',
            }"
            class="form-control form-control-sm"
            placeholder="Lebar (CM)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <!-- tinggi -->
        <b-form-group label="Tinggi (CM) :" label-size="sm">
          <cleave
            v-model="form.height"
            :options="{
              numeral: true,
              numeralDecimalMark: ',',
              numeralPositiveOnly: true,
              delimiter: '.',
            }"
            class="form-control form-control-sm"
            placeholder="Tinggi (CM)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <slot name="custom-fields" />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTbody,
  BTr,
  BTd,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import Cleave from 'vue-cleave-component';

export default defineComponent({
  name: 'BasicProductDimension',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTbody,
    BTr,
    BTd,
    Cleave,
  },
  data() {
    return {};
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  // watch if cleave input are empty then change it to 0
  watch: {
    'form.weight': {
      handler(val) {
        if (val === '') {
          this.form.stock = '0';
        }
      },
    },
    'form.height': {
      handler(val) {
        if (val === '') {
          this.form.height = '0';
        }
      },
    },
    'form.width': {
      handler(val) {
        if (val === '') {
          this.form.width = '0';
        }
      },
    },
    'form.length': {
      handler(val) {
        if (val === '') {
          this.form.length = '0';
        }
      },
    },
  },
});
</script>
