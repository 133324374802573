<template>
  <b-card title="Informasi Produk">
    <!-- sku -->
    <b-form-group label="Nomor SKU (Kode Barang) :" label-size="sm">
      <b-form-input
        v-model="form.sku"
        size="sm"
        autocomplete="off"
        placeholder="SKU"
      />
    </b-form-group>
    <!-- nama produk -->
    <b-form-group label="Nama Produk :" label-size="sm">
      <b-form-input
        v-model="form.title"
        size="sm"
        autocomplete="off"
        placeholder="Nama Produk"
      />
    </b-form-group>
    <!-- stok dan harga dibuat col 6 -->
    <b-row>
      <b-col cols="6">
        <!-- stok -->
        <b-form-group label="Stok :" label-size="sm">
          <cleave
            v-model="form.stock"
            :options="{
              numeral: true,
              numeralDecimalMark: ',',
              numeralPositiveOnly: true,
              delimiter: '.',
            }"
            class="form-control form-control-sm"
            placeholder="Stok"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <!-- harga -->
        <b-form-group label="Harga :" label-size="sm">
          <cleave
            v-model="form.price"
            :options="{
              numeral: true,
              numeralDecimalMark: ',',
              numeralPositiveOnly: true,
              delimiter: '.',
            }"
            class="form-control form-control-sm"
            placeholder="Harga"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- deskripsi -->
    <b-form-group label="Deskripsi Produk :" label-size="sm">
      <b-form-textarea
        v-model="form.description"
        size="sm"
        autocomplete="off"
        placeholder="Deskripsi Produk"
        rows="4"
        no-auto-shrink
        no-resize
      />
    </b-form-group>

    <slot name="custom-fields" />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTbody,
  BTr,
  BTd,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import Cleave from 'vue-cleave-component';

export default defineComponent({
  name: 'BasicProductInfoSection',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTbody,
    BTr,
    BTd,
    Cleave,
  },
  data() {
    return {};
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  // watch if cleave input are empty then change it to 0
  watch: {
    'form.stock': {
      handler(val) {
        if (val === '') {
          this.form.stock = '0';
        }
      },
    },
    'form.price': {
      handler(val) {
        if (val === '') {
          this.form.price = '0';
        }
      },
    },
  },
});
</script>
