var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Informasi Produk"}},[_c('b-form-group',{attrs:{"label":"Nomor SKU (Kode Barang) :","label-size":"sm"}},[_c('b-form-input',{attrs:{"size":"sm","autocomplete":"off","placeholder":"SKU"},model:{value:(_vm.form.sku),callback:function ($$v) {_vm.$set(_vm.form, "sku", $$v)},expression:"form.sku"}})],1),_c('b-form-group',{attrs:{"label":"Nama Produk :","label-size":"sm"}},[_c('b-form-input',{attrs:{"size":"sm","autocomplete":"off","placeholder":"Nama Produk"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Stok :","label-size":"sm"}},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"options":{
            numeral: true,
            numeralDecimalMark: ',',
            numeralPositiveOnly: true,
            delimiter: '.',
          },"placeholder":"Stok"},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Harga :","label-size":"sm"}},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"options":{
            numeral: true,
            numeralDecimalMark: ',',
            numeralPositiveOnly: true,
            delimiter: '.',
          },"placeholder":"Harga"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Deskripsi Produk :","label-size":"sm"}},[_c('b-form-textarea',{attrs:{"size":"sm","autocomplete":"off","placeholder":"Deskripsi Produk","rows":"4","no-auto-shrink":"","no-resize":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_vm._t("custom-fields")],2)}
var staticRenderFns = []

export { render, staticRenderFns }