var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Dimensi dan Berat"}},[_c('b-form-group',{attrs:{"label":"Berat (KG/GR) :","label-size":"sm"}},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"options":{
        numeral: true,
        numeralDecimalMark: ',',
        numeralPositiveOnly: true,
        delimiter: '.',
      },"placeholder":"Berat (KG)"},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Panjang (CM) :","label-size":"sm"}},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"options":{
            numeral: true,
            numeralDecimalMark: ',',
            numeralPositiveOnly: true,
            delimiter: '.',
          },"placeholder":"Panjang (CM)"},model:{value:(_vm.form.length),callback:function ($$v) {_vm.$set(_vm.form, "length", $$v)},expression:"form.length"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Lebar (CM) :","label-size":"sm"}},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"options":{
            numeral: true,
            numeralDecimalMark: ',',
            numeralPositiveOnly: true,
            delimiter: '.',
          },"placeholder":"Lebar (CM)"},model:{value:(_vm.form.width),callback:function ($$v) {_vm.$set(_vm.form, "width", $$v)},expression:"form.width"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Tinggi (CM) :","label-size":"sm"}},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"options":{
            numeral: true,
            numeralDecimalMark: ',',
            numeralPositiveOnly: true,
            delimiter: '.',
          },"placeholder":"Tinggi (CM)"},model:{value:(_vm.form.height),callback:function ($$v) {_vm.$set(_vm.form, "height", $$v)},expression:"form.height"}})],1)],1)],1),_vm._t("custom-fields")],2)}
var staticRenderFns = []

export { render, staticRenderFns }